import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Carousel, Flex, Image } from 'antd';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { api } from '~/services/api';
import breakpoints from '~/styles/breakpoints.module.scss';

import './SimilarAds.scss';

const breakpointSM = parseInt(breakpoints.mdMax.slice(0, -2));

function formatDate(date) {
  const d = new Date(date);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return d.toLocaleDateString('fr-FR', options);
}

function SimilarAds({ similarAdsBody }) {
  const carouselRef = useRef(null);

  const { isLoading, data } = useQuery({
    queryKey: ['similarAds', similarAdsBody],
    queryFn: () =>
      api.post('/product/similaires/', { body: similarAdsBody }).then((res) => {
        if (res.success) {
          return res.data.results;
        }
        return null;
      }),
  });

  if (isLoading || !data || data.length === 0) {
    return null;
  }

  const lowestPrice = data.reduce((acc, curr) => (acc < curr.price_with_vat ? acc : curr.price_with_vat), Infinity);
  const highestPrice = data.reduce((acc, curr) => (acc > curr.price_with_vat ? acc : curr.price_with_vat), 0);

  const groupedSimilarAds =
    window.innerWidth < breakpointSM
      ? data.map((ad) => [ad])
      : data.reduce((acc, curr) => {
          if (acc.length === 0) {
            acc.push([curr]);
            return acc;
          }
          const lastGroup = acc[acc.length - 1];
          if (lastGroup.length < 2) {
            lastGroup.push(curr);
            return acc;
          }
          acc.push([curr]);
          return acc;
        }, []);

  return (
    <>
      <Flex className='similar-ads-panel' vertical gap={16}>
        <div className='text-center'>
          <p className='body2'>Annonces similaires</p>
          <p className='caption2 text-grey-medium'>
            Prix conseillé entre {lowestPrice}€ et {highestPrice}€
          </p>
        </div>
        <Carousel ref={carouselRef} id='similar-ads-carousel' dots={{ className: 'similar-ads-caroussel-dots' }}>
          {groupedSimilarAds.map((groupedAds, index) => (
            <div key={`grouped-similar-ad-${index}`}>
              <Flex justify='center' gap={10}>
                {groupedAds.map((ad, index) => (
                  <Flex key={`similar-ad-${index}`} gap={16} vertical align='center' className='similar-ad-flex-wrapper'>
                    <div className='similar-ad-image-wrapper'>
                      <Image className='similar-ad-image' src={ad.image} width='auto' />
                    </div>
                    <p className='body2 similar-ad-title'>{ad.title}</p>
                    <Flex className='similar-ad-description' gap={8}>
                      <Flex className='similar-ad-description-left-wrapper' vertical gap={8}>
                        {ad.etat && <p className='body1 text-grey-medium'>État</p>}
                        <p className='body1 text-grey-medium'>Prix</p>
                        <p className='body1 text-grey-medium'>Date de publication</p>
                        <p className='body1 text-grey-medium'>Stock</p>
                        {ad.temps_vente && <p className='body1 text-grey-medium'>Vendu en</p>}
                        <p className='body1 text-grey-medium'>Marchand·e</p>
                      </Flex>
                      <Flex className='similar-ad-description-right-wrapper' vertical gap={8}>
                        {ad.etat && <p className='body1'>{ad.etat.charAt(0).toUpperCase() + ad.etat.slice(1)}</p>}
                        <p className='body1'>{ad.price_with_vat}€</p>
                        <p className='body1'>{formatDate(ad.created_on)}</p>
                        <p className='body1'>{ad.sold ? 'Vendu' : 'En stock'}</p>
                        {ad.temps_vente && (
                          <p className='body1'>
                            {ad.temps_vente} {ad.temps_vente_unite}
                            {ad.temps_vente > 1 ? 's' : ''}
                          </p>
                        )}
                        <p className='body1'>{ad.merchant_name}</p>
                      </Flex>
                    </Flex>
                    <Flex className='similar-ad-link-wrapper' vertical justify='flex-end'>
                      <Button className='similar-ad-link' type='link' href={`${ad.url}?labo_similar_product=true`} target='_blank' rel='noopener noreferrer'>
                        Voir l'annonce
                      </Button>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </div>
          ))}
        </Carousel>
        {groupedSimilarAds.length > 1 && (
          <>
            <Button type='text' className='btn-icon carousel-btn next' onClick={() => carouselRef.current.next()} size='large' icon={<RightOutlined />} />
            <Button type='text' className='btn-icon carousel-btn prev' onClick={() => carouselRef.current.prev()} size='large' icon={<LeftOutlined />} />
          </>
        )}
      </Flex>
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const title = form_fields[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE];
  const category = form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];

  const similarAdsBody = JSON.stringify({
    components: [
      {
        [AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE]: title,
        [AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE]: category,
      },
    ],
  });
  return {
    similarAdsBody,
  };
})(SimilarAds);
