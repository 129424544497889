import { Layout } from 'antd';
import { useAtomValue } from 'jotai';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { adsCatalogueDataWithCookieAtom } from '~/atoms';
import BackdropHeader from '~/components/nav/BackdropHeader/BackdropHeader';
import SideHeader from '~/components/nav/SideHeader/SideHeader';
import AdDetails from '~/components/screens/AdDetails/AdDetails';
import AdsCatalogue from '~/components/screens/AdsCatalogue/AdsCatalogue';
import Conversations from '~/components/screens/Conversations/Conversations';
import Home from '~/components/screens/Home/Home';
import OrderDetails from '~/components/screens/OrderDetails/OrderDetails';
import OrdersCatalogue from '~/components/screens/OrdersCatalogue/OrdersCatalogue';
import { RoutingUrl } from '~/helpers/general';
import { ACTIVE_MERCHANT_UPDATE } from '~/store/reducers/session';
import breakpoints from '~/styles/breakpoints.module.scss';

import './MainNavigator.scss';

const { Content } = Layout;

const breakpointMobile = parseInt(breakpoints.mdMax.slice(0, -2));
const FOCUS_ROUTES = [RoutingUrl.adCreation(), RoutingUrl.adEdition([':product_id'])];

function activateMerchant() {
  const dispatch = useDispatch();
  let { search } = useLocation();
  if (search && search.includes('activateMerchant')) {
    const activateMerchant = search
      .slice(1)
      .split('&')
      .map((param) => param.split('='))
      .find((param) => param[0] === 'activateMerchant');
    if (activateMerchant && activateMerchant.length === 2) {
      const merchantId = activateMerchant[1];
      dispatch({
        type: ACTIVE_MERCHANT_UPDATE,
        payload: { merchant_id: merchantId },
      });
    }
  }
}

function Redirections() {
  return (
    <>
      <Route path='*'>
        <Redirect to={RoutingUrl.home()} />
      </Route>
    </>
  );
}

// This is debt to actualize adscatalogue based on the cookie of data type
function AdsCatalogueWrapper(props) {
  const dataType = useAtomValue(adsCatalogueDataWithCookieAtom);
  return <AdsCatalogue {...props} dataType={dataType} />;
}

export default function MainNavigator() {
  const panelRef = useRef(null);
  const match = useRouteMatch(FOCUS_ROUTES);
  const isFocus = match !== null && window.innerWidth <= breakpointMobile;
  activateMerchant();

  return (
    <>
      {/* <OnBoarding /> */}
      <Layout hasSider className='layout-main-navigator-block'>
        <SideHeader />
        <Content>
          <BackdropHeader isFocus={isFocus}>
            <div className={`panel full-size-panel overflow-hidden${isFocus ? ' focused' : ''}`}>
              <div className='inner-panel' ref={panelRef}>
                <Switch>
                  <Route exact path={RoutingUrl.home()} component={Home} />
                  <Route exact path={RoutingUrl.catalogue()} component={AdsCatalogueWrapper} />
                  <Route
                    exact
                    path={[RoutingUrl.adCreation(), RoutingUrl.adEdition([':product_id'])]}
                    render={(routeProps) => <AdDetails {...routeProps} isFocus={isFocus} panelRef={panelRef} />}
                  />
                  <Route exact path={RoutingUrl.orders()} render={(routeProps) => <OrdersCatalogue {...routeProps} panelRef={panelRef} />} />
                  <Route exact path={RoutingUrl.orderDetails([':order_id'])} render={(routeProps) => <OrderDetails {...routeProps} panelRef={panelRef} />} />
                  <Route exact path={RoutingUrl.conversations()} render={(routeProps) => <Conversations {...routeProps} panelRef={panelRef} />} />
                  <Redirections />
                </Switch>
              </div>
            </div>
          </BackdropHeader>
        </Content>
      </Layout>
    </>
  );
}
